import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildConfigurationTablesBaseUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { Table, Tables } from '../types';

export const loadTables = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: Table[]; actionName: string; processResultFunction: Function }> => {
    const url = buildConfigurationTablesBaseUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<Tables>) => {
            const { Table } = response;
            const result = convertToCamel<Table[]>(Table);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
